import React, { useMemo } from 'react';

import { Box, Grid, Typography } from '@mui/material';
// import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
// import { useNavigate } from 'react-router-dom';

// import AlertPopup from 'components/alert-popup/AlertPopup';
import {
  useGetAllMaterialsQuery,
  // useRemoveCartItemMutation,
} from 'store/apis/order';
import { pxToRem } from 'theme-loader/theme/typography';
// import { useNotifyToast } from 'utils/useNotifyToast';

const headerSx = {
  fontSize: pxToRem(15),
  fontWeight: 600,
};

export const MaterialItem = ({ title, value, type, sx, sm, md, lg }) => {
  return (
    <Grid
      item
      xs={12}
      sm={sm || 12}
      md={md || 5}
      lg={lg || 5}
      xl={4}
      mt={1}
      sx={{
        display: 'flex',
        alignItems: 'flex-start',
        ...sx,
      }}
    >
      <Typography
        sx={{
          fontSize: pxToRem(13),
          fontWeight: 600,
          color: '#757575',
          // mr: 1,
          textWrap: 'nowrap',
        }}
      >
        {title}:
      </Typography>
      <Typography
        sx={{
          fontSize: pxToRem(13),
          fontWeight: 300,
        }}
        // pr={1}
      >
        &nbsp;
        {value}
      </Typography>
    </Grid>
  );
};

MaterialItem.propTypes = {
  title: PropTypes.string,
  value: PropTypes.string,
  type: PropTypes.string,
  sx: PropTypes.object,
  sm: PropTypes.number,
  md: PropTypes.number,
  lg: PropTypes.number,
};

const CartItem = (props) => {
  const { cartItem, extraInfo = null } = props;
  const { data: materials } = useGetAllMaterialsQuery();

  const cabinetDetail = useMemo(
    () => materials?.cabinets?.find((data) => data?.code === cartItem?.code),
    [cartItem?.code, materials?.cabinets]
  );

  const { data: materialData } = useGetAllMaterialsQuery();
  const { adjFeetPlacementOptions, adjLegBoringOptions, leftRightEndOptions } =
    materialData || {};

  const adjustmentGroup = [];

  const titleObj = {};
  if (cartItem?.adjustments?.adjustmentShelves) {
    titleObj.adjustmentShelves = {
      name: 'Adjustment Shelves',
      unit: cartItem?.adjustments?.adjustmentShelves > 1 ? ' pcs' : ' pc',
    };
  }

  cabinetDetail?.adjustmentParameters?.map(
    (item) =>
      (titleObj[item.id] = {
        name: item.desc,
        unit: item.unit === 'inch' ? '"' : item.unit ? item.unit : '"',
      })
  );

  cartItem?.adjustments &&
    Object.entries(cartItem?.adjustments)?.forEach(([key, value]) => {
      if (value !== null && value !== '' && value !== '0') {
        adjustmentGroup.push({
          title: titleObj[key]?.name,
          value: value,
          unit: titleObj[key]?.unit,
        });
      }
    });

  const leftEnd = leftRightEndOptions?.find((optionItem) => {
    return optionItem.value === cartItem.leftEnd;
  });
  const rightEnd = leftRightEndOptions?.find(
    (optionItem) => Number(optionItem.value) === Number(cartItem.rightEnd)
  );

  const legBore = adjLegBoringOptions?.find(
    (optionItem) => optionItem.value === cartItem.legBore
  );
  const legs = adjFeetPlacementOptions?.find(
    (optionItem) => optionItem.value === cartItem.legs
  );
  return (
    <>
      <Box>
        <Box mt={3} mb={2}>
          <Typography sx={{ ...headerSx }}>SETTINGS</Typography>
          <Grid container alignItems={'flex-start'}>
            {cartItem?.toekick ? (
              <MaterialItem
                title="TOEKICK HEIGHT"
                value={cartItem.toekickHeight + '"'}
                type="settings"
              />
            ) : null}
            {cartItem?.toekick ? (
              <MaterialItem
                title="TOEKICK DEPTH"
                value={cartItem.toekickDepth + '"'}
                type="settings"
              />
            ) : null}
            {cartItem?.type !== 'WALL' ? (
              <MaterialItem
                title="ADJUSTABLE FEET BORING"
                value={legBore?.option}
                type="settings"
              />
            ) : null}
            {cartItem?.type !== 'WALL' &&
            legBore?.option?.toLowerCase() !== 'none' ? (
              <MaterialItem
                title="ADJUSTABLE FEET PLACEMENT"
                value={legs?.option}
                type="settings"
              />
            ) : null}
            <MaterialItem
              title="LEFT END"
              value={leftEnd?.option}
              type="settings"
            />
            <MaterialItem
              title="RIGHT END"
              value={rightEnd?.option}
              type="settings"
            />
            <MaterialItem
              title="GRAIN PATTERN"
              value={cartItem.grainName}
              type="settings"
            />
            {cartItem?.type === 'WALL' || cartItem?.type === 'BASE' ? (
              <MaterialItem
                title="HANG RAIL NOTCHES"
                value={cartItem?.hangRailNotches}
                type="settings"
              />
            ) : null}
          </Grid>
        </Box>
        {cartItem?.reveals && (
          <Box mb={2}>
            <Typography sx={{ ...headerSx }}>Reveals</Typography>
            <Grid container>
              <MaterialItem
                title="LEFT"
                value={cartItem.leftReveal + '"'}
                type="settings"
              />
              <MaterialItem
                title="RIGHT"
                value={cartItem.rightReveal + '"'}
                type="settings"
              />
            </Grid>
            <Grid container alignItems={'flex-start'}>
              <MaterialItem
                title="TOP"
                value={cartItem.topReveal + '"'}
                type="settings"
              />
              <MaterialItem
                title="BOTTOM"
                value={cartItem.bottomReveal + '"'}
                type="settings"
              />
            </Grid>
          </Box>
        )}
        {adjustmentGroup.length > 0 && (
          <Box mb={2}>
            <Typography sx={{ ...headerSx }}>Adjustments</Typography>
            <Grid container>
              {adjustmentGroup.map((parameter) => (
                <MaterialItem
                  key={parameter?.title}
                  title={parameter?.title}
                  value={parameter?.value + parameter?.unit}
                />
              ))}
            </Grid>
          </Box>
        )}
        <Box mb={2}>
          <Typography sx={{ ...headerSx }}>
            Customer notes and instructions
          </Typography>
          <Grid container>
            <Typography
              sx={{
                fontSize: pxToRem(13),
                fontWeight: 600,
                color: '#757575',
                display: 'inline',
              }}
            >
              Special Manufacturing Instructions or Requests:{' '}
            </Typography>
            <Typography
              sx={{
                fontSize: pxToRem(13),
                fontWeight: 300,
                display: 'inline',
              }}
              pr={1}
            >
              {cartItem.specialInstructions || '-'}
            </Typography>
          </Grid>
          <Grid container>
            <Typography
              sx={{
                fontSize: pxToRem(13),
                fontWeight: 600,
                color: '#757575',
                display: 'inline',
                mr: 1,
              }}
            >
              Notes:{' '}
            </Typography>
            <Typography
              sx={{
                fontSize: pxToRem(13),
                fontWeight: 300,
                display: 'inline',
              }}
              pr={1}
            >
              {cartItem.note || '-'}
            </Typography>
          </Grid>
        </Box>
        {extraInfo && extraInfo}
      </Box>
    </>
  );
};

CartItem.propTypes = {
  cartItem: PropTypes.object,
  extraInfo: PropTypes.any,
};

export default CartItem;
