import React, { useState } from 'react';

import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import AccordionCustom from 'components/accordian/AccordionCustom';
import CartItem, { MaterialItem } from 'components/cart-item/CartItem';
import NestedAccordion from 'components/common/NestedAccordion';
import { headerSx } from 'pages/your-order/YourOrder';
import { setSelectedProject } from 'store/slices/projectSlice';
import { pxToRem } from 'theme-loader/theme/typography';

const NestedChildComponent = ({ isLoading = false, data = null }) => {
  const {
    caseMaterialMeta,
    doorMaterialMeta,
    drawerBoxMeta,
    assemblyMeta,
    caseBandLF,
  } = data;

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [accordion, setAccordion] = useState({});

  const handleChange = (id) => {
    setAccordion((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  const materialItems = [
    {
      title: 'CASE MATERIAL',
      value: caseMaterialMeta?.name,
      type: 'settings',
    },
    {
      title: 'DOOR MATERIAL',
      value: doorMaterialMeta?.name,
      type: 'settings',
    },
    {
      title: 'DRAWER MATERIAL',
      value: drawerBoxMeta?.name,
      type: 'settings',
    },
    {
      title: 'ASSEMBLY',
      value: assemblyMeta?.name,
      type: 'settings',
    },
    {
      title: 'CASE EDGEBANDING',
      value:
        caseBandLF === 'matchCase'
          ? 'Match Case Material'
          : 'Match Door Material',
      type: 'settings',
    },
  ];

  return isLoading ? (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
      }}
    >
      <CircularProgress />
    </Box>
  ) : (
    <Box>
      <Box px={2}>
        <Box mb={2}>
          <Typography sx={{ ...headerSx, mt: 3 }}>MATERIALS</Typography>
          <Grid
            container
            columnSpacing={4}
            rowSpacing={1}
            alignItems={'flex-start'}
          >
            {materialItems?.map((mat) => (
              <MaterialItem
                key={mat?.title}
                title={mat?.title}
                value={mat?.value}
                type={mat?.type}
              />
            ))}
          </Grid>
        </Box>
        <Box
          display={'flex'}
          flexDirection={'row'}
          alignItems={'center'}
          justifyContent={'flex-end'}
        >
          <Button
            variant="contained"
            size="small"
            sx={{
              'fontSize': pxToRem(12),
              'fontWeight': 700,
              'backgroundColor': '#f0f0f0',
              'color': '#DB401A',
              'py': 1,
              'width': 100,
              'borderRadius': 2,
              '&:hover': {
                backgroundColor: '#E5E5E5',
                color: '#DB401A',
              },
            }}
            onClick={() => {
              if (Object.values(accordion).some((item) => !!item)) {
                setAccordion({});
              } else {
                const final = {};
                data?.cabinets.forEach((item) => {
                  final[item.id] = true;
                });
                setAccordion(final);
              }
            }}
          >
            {data?.cabinets?.length &&
            Object.values(accordion).some((item) => !!item)
              ? 'Collapse All'
              : 'Expand All'}
          </Button>
        </Box>

        <Box mb={data?.id ? 0 : 25} pt={2.5}>
          {data?.cabinets?.length &&
            data?.cabinets?.map((item, index) => {
              return (
                <Box key={item?.id}>
                  <AccordionCustom
                    title={
                      <Stack>
                        <Grid container>
                          <Grid
                            item
                            xs={8}
                            sm={9}
                            md={10.5}
                            sx={{ display: 'flex', alignItems: 'center' }}
                          >
                            <Grid container spacing={'10px'}>
                              <Grid
                                item
                                xs={12}
                                md={2}
                                sx={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  gap: '15px',
                                }}
                              >
                                <Typography
                                  variant="h4"
                                  sx={{
                                    fontSize: pxToRem(16),
                                    // mr: 2,
                                  }}
                                >
                                  {index + 1}.
                                </Typography>
                                <Typography
                                  variant="h4"
                                  sx={{
                                    fontSize: pxToRem(16),
                                    fontWeight: 600,
                                  }}
                                >
                                  {item?.code}
                                </Typography>
                              </Grid>
                              <Grid item xs={12} sm={12} md={6} lg={3}>
                                <Typography
                                  sx={{
                                    fontSize: pxToRem(16),
                                  }}
                                >
                                  {item?.shortName}
                                </Typography>
                              </Grid>
                              <Grid item xs={12} sm={12} md={4} lg={4}>
                                <Box
                                  sx={{
                                    display: 'flex',
                                    justifyContent: { md: 'center' },
                                    alignItems: 'center',
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      fontSize: pxToRem(16),
                                      mr: { xs: 1, sm: 2, lg: 5 },
                                      mb: { xs: 1, sm: 0 },
                                    }}
                                  >
                                    {item.width && `W: ${item.width}"`}{' '}
                                    {item.height &&
                                      `${item.width ? 'X' : ''} ${
                                        item.isLengthType ? 'L' : 'H'
                                      }: ${item.height}"`}{' '}
                                    {item.depth &&
                                      `${
                                        item.width || item?.height ? 'X' : ''
                                      } D: ${item.depth}"`}
                                  </Typography>
                                </Box>
                              </Grid>
                              <Grid item lg={3}>
                                <Box
                                  sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      fontSize: pxToRem(16),
                                      mr: 1,
                                    }}
                                  >
                                    {item.quantity}
                                  </Typography>
                                  <Typography sx={{ mr: 1 }}>X</Typography>
                                  <Typography
                                    sx={{
                                      fontSize: pxToRem(16),
                                      textAlign: 'center',
                                    }}
                                  >
                                    $
                                    {Number(
                                      item.itemTotal / item.quantity
                                    )?.toFixed(2)}
                                  </Typography>
                                  <Typography
                                    sx={{
                                      fontSize: pxToRem(16),
                                      textAlign: 'center',
                                      mx: 1,
                                    }}
                                  >
                                    =
                                  </Typography>
                                  <Typography
                                    sx={{
                                      fontSize: pxToRem(16),
                                      fontWeight: 600,
                                      textAlign: 'right',
                                    }}
                                  >
                                    ${Number(item.itemTotal)?.toFixed(2)}
                                  </Typography>
                                </Box>
                              </Grid>
                            </Grid>
                          </Grid>

                          <Grid xs={3} sm={3} md={1.5} item>
                            <Box
                              sx={{
                                mr: { xs: '-20px', sm: '10px' },
                                float: 'right',
                              }}
                            >
                              <Tooltip
                                title={
                                  item.isDeleted
                                    ? 'This cabinet no longer exists'
                                    : `Duplicate ${item?.code} cabinet`
                                }
                              >
                                <span
                                  style={{
                                    display: 'inline-block',
                                  }}
                                >
                                  <IconButton
                                    disabled={item?.isDeleted}
                                    size="large"
                                    sx={{ p: 1, float: 'right' }}
                                    onClick={() => {
                                      if (data) {
                                        dispatch(setSelectedProject(data));
                                      }

                                      const payload = { ...item };
                                      delete payload.project;
                                      navigate('/customize', {
                                        state: {
                                          selectedCabinet: {
                                            ...payload,
                                            ...item?.project,
                                          },
                                          isDuplicate: true,
                                        },
                                      });
                                    }}
                                    color="inherit"
                                  >
                                    <ContentCopyIcon />
                                  </IconButton>
                                </span>
                              </Tooltip>
                            </Box>
                          </Grid>
                        </Grid>
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                          }}
                        >
                          <Stack>
                            {item.note?.trim()?.length ? (
                              <Typography sx={{ fontWeight: 600 }}>
                                Notes:{' '}
                                <span style={{ fontWeight: 400 }}>
                                  {item.note?.trim()}
                                </span>
                              </Typography>
                            ) : null}
                            {item.specialInstructions?.trim()?.length ? (
                              <Typography sx={{ fontWeight: 600 }}>
                                Special Instructions:{' '}
                                <span style={{ fontWeight: 400 }}>
                                  {item.specialInstructions?.trim()}
                                </span>
                              </Typography>
                            ) : null}
                          </Stack>
                        </Box>
                      </Stack>
                    }
                    expanded={!!accordion?.[item?.id]}
                    handleChange={() => handleChange(item?.id)}
                    sx={{
                      '& .MuiAccordionSummary-root': {
                        background: '#f0f0f0',
                      },
                      '&::before': {
                        backgroundColor: 'transparent',
                      },
                    }}
                  >
                    <CartItem
                      cartItem={item}
                      key={item.id}
                      extraInfo={
                        <Box>
                          <Typography sx={{ ...headerSx }}>
                            Cost Info:
                          </Typography>
                          <Grid container alignItems={'flex-start'}>
                            {[
                              {
                                title: 'Case Material Cost',
                                value: item?.caseMaterialCost,
                              },
                              {
                                title: 'Finished End Material Cost',
                                value: item?.finishedEndMaterialCost,
                              },
                              {
                                title: 'Hardware Total Cost',
                                value: item?.hardwareTotalCost,
                              },
                              {
                                title: 'Drawer Total Cost',
                                value: item?.drawerTotalCost,
                              },
                              {
                                title: 'CNC Per Sheet Case Cost',
                                value: item?.cncPerSheetCaseCost,
                              },
                              {
                                title: 'CNC Per Sheet Door Cost',
                                value: item?.cncPerSheetDoorCost,
                              },
                              {
                                title: 'Lf Banding Case Cost',
                                value: item?.lfBandingCaseCost,
                              },
                              {
                                title: 'Lf Banding Door Cost',
                                value: item?.lfBandingDoorCost,
                              },
                              {
                                title: 'Processing Hardware Cost',
                                value: item?.processingHardwareCost,
                              },
                              {
                                title: 'Processing Total Cost',
                                value: item?.processingTotalCost,
                              },
                              {
                                title: 'Assembly And Handling Cost',
                                value: item?.assemblyAndHandlingCost,
                              },
                              { title: 'Cost Each', value: item?.costEach },
                              { title: 'Sell Each', value: item?.sellEach },
                              { title: 'Total', value: item?.itemTotal },
                            ]?.map((item, index) => (
                              <MaterialItem
                                key={index}
                                title={item?.title}
                                value={parseFloat(item?.value)?.toFixed(2)}
                                type="settings"
                                isBold
                              />
                            ))}
                          </Grid>
                        </Box>
                      }
                    />
                  </AccordionCustom>
                  <Divider
                    variant="fullWidth"
                    sx={{
                      border: (theme) => `1px solid ${theme.palette.grey[400]}`,
                    }}
                  />
                </Box>
              );
            })}
        </Box>
      </Box>
    </Box>
  );
};

const OrderedProjects = ({ data, row, projectType }) => {
  const processedData = {};
  const orderItems =
    projectType === 'invoiced'
      ? data?.orderItems?.filter(
          (item) => item.project.projectId === row.projectId
        )
      : data?.orderItems;
  processedData.projectData = (orderItems ?? []).reduce((acc, item) => {
    const isDeleted = row?.projects
      ? !row.projects
          ?.flatMap((project) => project.cabinetCodes)
          ?.find((cabinet) => cabinet.name === item.code)?.exists
      : !row.cabinetCodes?.find((cabinet) => cabinet.name === item.code)
          ?.exists;

    if (!item?.project?.projectName) return acc;

    const existingProject = acc.find(
      (p) => p?.projectName === item.project.projectName
    );

    if (existingProject) {
      existingProject.cabinets.push({
        ...item,
        grainName: item?.project?.grainName,
        isDeleted,
      });
    } else {
      acc.push({
        ...item.project,
        cabinets: [{ ...item, grainName: item?.project?.grainName, isDeleted }],
      });
    }

    return acc;
  }, []);

  return (
    <NestedAccordion
      data={processedData}
      childComponent={({ item }) => {
        return <NestedChildComponent data={item} />;
      }}
    />
  );
};

OrderedProjects.propTypes = {
  data: PropTypes.shape({
    map: PropTypes.func,
    orderItems: PropTypes.array,
    projects: PropTypes.any,
  }),
  orderItems: PropTypes.any,
  projectType: PropTypes.string,
  row: PropTypes.shape({
    cabinetCodes: PropTypes.shape({
      find: PropTypes.func,
    }),
    projectId: PropTypes.any,
    projects: PropTypes.shape({
      flatMap: PropTypes.func,
    }),
  }),
};

export default OrderedProjects;

NestedChildComponent.propTypes = {
  cabinets: PropTypes.any,
  data: PropTypes.shape({
    assemblyMeta: PropTypes.shape({
      name: PropTypes.any,
    }),
    caseBandLF: PropTypes.string,
    caseMaterialMeta: PropTypes.shape({
      name: PropTypes.any,
    }),
    doorMaterialMeta: PropTypes.shape({
      name: PropTypes.any,
    }),
    drawerBoxMeta: PropTypes.shape({
      name: PropTypes.any,
    }),
    id: PropTypes.any,
    length: PropTypes.func,
    map: PropTypes.func,
    projectData: PropTypes.shape({
      length: PropTypes.any,
      map: PropTypes.func,
    }),
  }),
  isLoading: PropTypes.bool,
};
