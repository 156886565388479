import React, { useEffect, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import {
  Box,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Paper,
  Stack,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import moment from 'moment';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';

import AlertPopup from 'components/alert-popup/AlertPopup';
import ButtonSx from 'components/button/ButtonSx';
import ProjectPopup from 'components/project-popup/ProjectPopup';
import StickyFooter from 'components/sticky-footer/StickyFooter';
import useAuth from 'context/AuthContext';
import OrderItemDialog from 'pages/orders/components/OrderItemDialog';
import { datagridSx, defaultMaterial } from 'pages/orders/Orders';
import {
  useCreateDuplicateProjectsMutation,
  useDeleteProjectMutation,
  useGetAllMaterialsQuery,
  useGetAllProjectsOfUserQuery,

  // useGetOrdersQuery,
} from 'store/apis/order';
import {
  setSelectedProject,
  setSelectedProjectForOptions,
} from 'store/slices/projectSlice';
import { pxToRem } from 'theme-loader/theme/typography';
import { getLastWorkedOnProject } from 'utils/commonFunctions';
import { useNotifyToast } from 'utils/useNotifyToast';

const tabOptions = [
  { label: 'Drafts', value: 'drafts', component: () => {} },
  { label: 'Invoiced', value: 'invoiced', component: () => {} },
];

const validationSchema = yup.object({
  projects: yup.array().of(
    yup.object({
      name: yup.string().required('Project name cannot be empty'),
    })
  ),
});
const MyProjects = () => {
  const { path } = useParams();
  const navigate = useNavigate();
  const auth = useAuth();
  const dispatch = useDispatch();

  const handleChange = (event, newValue) => {
    navigate(`/my-projects/${newValue}`);
  };
  const [filter, setFilter] = React.useState({
    sortBy: 'createdAt',
    orderBy: 'desc',
    page: 1,
    limit: 10,
  });
  const [sortModel, setSortModel] = useState({});
  const [openDialog, setOpenDialog] = React.useState(false);

  const [selectedItem, setSelectedItem] = React.useState(null);
  const [selectedRows, setSelectedRows] = React.useState([]);
  const [isProjectPopupOpen, setProjectPopupOpen] = useState(false);
  const [selectionModel, setSelectionModel] = useState([]);
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [confirmValue, setConfirmValue] = useState('');
  const [selectedRowIds, setSelectedRowIds] = useState([]);

  const {
    control,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      projectsFields: [],
    },
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
  });

  const [
    deleteProject,
    {
      isLoading: isProjectDeleting,
      isSuccess: isProjectDeleteSuccess,
      isError: isDeleteProjectError,
      error: deleteProjectError,
    },
  ] = useDeleteProjectMutation();

  const {
    data: fetchMyProjects,
    isLoading,
    isFetching,
  } = useGetAllProjectsOfUserQuery(
    { projectType: path, ...filter },
    { skip: !auth?.authenticated, refetchOnMountOrArgChange: true }
  );

  const { data: allProjects } = useGetAllProjectsOfUserQuery(
    {},
    { skip: !auth?.authenticated, refetchOnMountOrArgChange: true }
  );

  const [
    createDuplicateProjects,
    {
      isLoading: isDuplicatePLoading,
      isSuccess: isDuplicatePSuccess,
      isError: isDuplicateProjectError,
      error: duplicatePError,
    },
  ] = useCreateDuplicateProjectsMutation();

  const { data: materials } = useGetAllMaterialsQuery();

  const { caseMaterials, doorMaterials } = materials || {};

  const notifyToast = useNotifyToast();

  useEffect(() => {
    if (!isProjectDeleting && isProjectDeleteSuccess && confirmValue) {
      if (fetchMyProjects?.length) {
        const projects = fetchMyProjects?.filter(
          (project) => project?.id !== selectedRowIds?.at(-1)
        );
        const lastWorkedOnProject = getLastWorkedOnProject(projects || []);
        dispatch(setSelectedProject(lastWorkedOnProject));
        dispatch(setSelectedProjectForOptions(lastWorkedOnProject));
      } else {
        dispatch(setSelectedProject({}));
        dispatch(setSelectedProjectForOptions({}));
      }
      setConfirmValue('');
      setIsAlertOpen(false);
      notifyToast(
        `${selectedRowIds?.length} Project${
          selectedRowIds?.length > 1 ? 's' : ''
        } deleted successfully!`,
        'projectDeleteSuccess',
        'success'
      );
    } else if (isDeleteProjectError) {
      notifyToast(
        deleteProjectError?.data?.errorMessage,
        'updateError',
        'error'
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    deleteProjectError?.data?.errorMessage,
    deleteProjectError,
    isProjectDeleting,
    isProjectDeleteSuccess,
    dispatch,
    navigate,
    notifyToast,
  ]);

  const handleItemDelete = () => {
    deleteProject({ ids: selectedRowIds });
  };

  useEffect(() => {
    if (!isDuplicatePLoading && isDuplicatePSuccess) {
      setProjectPopupOpen(false);
      notifyToast(
        'Project duplicated successfully!',
        'projectDuplicateSuccess',
        'success'
      );
    } else if (isDuplicateProjectError) {
      notifyToast(duplicatePError?.data?.errorMessage, 'updateError', 'error');
    }
  }, [
    duplicatePError?.data?.errorMessage,
    isDuplicatePLoading,
    isDuplicatePSuccess,
    isDuplicateProjectError,
    notifyToast,
  ]);

  useEffect(() => {
    if (sortModel && sortModel?.length) {
      const { field, sort } = sortModel[0];
      setFilter((prevState) => ({
        ...prevState,
        sortBy: field,
        orderBy: sort,
      }));
    }
  }, [sortModel]);

  const mergedObject = {};
  selectedRows?.forEach((project) => {
    let name = project?.name || project?.projectName;
    if (!mergedObject[name]) {
      mergedObject[name] = 1;
    }
    let projectId = path === 'invoiced' ? project?.projectId : project?.id;
    allProjects?.forEach((item) => {
      if (item?.duplicatedFrom === projectId) {
        mergedObject[name] = mergedObject[name] + 1;
      }
    });
  });
  const [projectsWithInvalidMaterials, setProjectsWithInvalidMaterials] =
    useState([]);
  const [matSelectionPage, setMatSelectionPage] = useState({ page: 0 });

  const resetState = () => {
    setSelectionModel([]);
    setSelectedRows([]);
    reset();
    setProjectPopupOpen(false);
    setProjectsWithInvalidMaterials([]);
    setFormData({});
    setMatSelectionPage({ page: 0 });
  };

  const handleClose = () => {
    if (matSelectionPage.page === 1) {
      setMatSelectionPage({ page: 0 });
    } else {
      resetState();
    }
  };

  const columns = [
    {
      field: 'createdAt',
      headerName: 'Date',
      sortable: true,
      width: 300,
      renderCell: (params) => {
        return moment(params?.row?.createdAt).format('MMMM DD, YYYY');
      },
    },
    {
      field: 'projectName',
      headerName: 'Projects',
      sortable: false,
      flex: 1,
      minWidth: 150,
      renderCell: (params) => {
        return (
          <Stack justifyContent={'center'} height={'100%'}>
            <Tooltip
              title={params?.row?.name || params?.row?.projectName}
              placement="top"
              arrow
            >
              <Typography
                noWrap
                sx={{
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  maxWidth: '100%',
                  width: 'fit-content',
                }}
              >
                {params?.row?.name || params?.row?.projectName}
              </Typography>
            </Tooltip>
          </Stack>
        );
      },
    },

    path !== 'drafts' && {
      field: 'purchaseOrderNo',
      headerName: 'Order #',
      sortable: false,
      minWidth: 145,
    },
    {
      field: 'Action',
      headerName: 'Actions',
      sortable: false,
      width: 150,
      renderCell: (params) => {
        return (
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              if (path === 'drafts') {
                dispatch(setSelectedProject(params?.row));
                dispatch(setSelectedProjectForOptions(params?.row));
                navigate('/projects/cabinets', {
                  state: {
                    projectData: params?.row,
                  },
                });
              } else {
                setOpenDialog(true);
                setSelectedItem({
                  row: params?.row,
                  id: params?.row?.orderInfoId || params?.row?.id,
                });
              }
            }}
          >
            <Tooltip
              title={`View ${path === 'drafts' ? '/ Edit ' : ''}Details`}
            >
              {path === 'drafts' ? (
                <ModeEditIcon
                  sx={{ color: (theme) => theme.palette.success.light }}
                />
              ) : (
                <RemoveRedEyeIcon />
              )}
            </Tooltip>
          </IconButton>
        );
      },
    },
  ].filter(Boolean);

  const checkForInvalidMat = (selectedData) => {
    const materialIds = selectedData?.reduce((acc, arr) => {
      const projectId = path === 'invoiced' ? arr.projectId : arr?.id;

      if (!acc[projectId]) {
        acc[projectId] = { case: '', door: '' };
      }

      acc[projectId].case = arr?.caseMaterialId;
      acc[projectId].door = arr?.doorMaterialId;
      acc[projectId].projectName =
        path !== 'invoiced' ? arr?.name : arr?.projectName;

      return acc;
    }, {});

    const doorMaterialIdList =
      materials?.doorMaterials?.map((item) => item.id) || [];
    const caseMaterialIdList =
      materials?.caseMaterials?.map((item) => item.id) || [];

    const validationResult = Object.entries(materialIds).reduce(
      (result, [projectId, materials]) => {
        result[projectId] = {
          case: caseMaterialIdList.includes(materials.case),
          door: doorMaterialIdList.includes(materials.door),
          projectName: materials.projectName,
        };
        return result;
      },
      {}
    );

    const projectsWithInvalidMaterials = Object.entries(validationResult)
      .filter(
        ([_, { case: isCaseValid, door: isDoorValid }]) =>
          !isCaseValid || !isDoorValid
      )
      .map(([projectId, materials]) => {
        return {
          projectId,
          ...materials,
        };
      });

    if (projectsWithInvalidMaterials?.length) {
      setProjectsWithInvalidMaterials(projectsWithInvalidMaterials);
    }
  };

  const [formData, setFormData] = useState({});

  const handleProjectDataChange = (type, value, projectName) => {
    setFormData((prev) => {
      const existingIndex = prev.findIndex(
        (item) => item?.projectName === projectName
      );

      const updatedItem = {
        projectName,
        value,
      };

      if (existingIndex !== -1) {
        return prev.map((item, index) => {
          if (index === existingIndex) {
            if (type === 'Door') {
              return { ...item, doorType: 'Door', doorValue: value };
            }
            if (type === 'Case') {
              return { ...item, caseType: 'Case', caseValue: value };
            }
            return item;
          }
          return item;
        });
      } else {
        if (type === 'Door') {
          updatedItem.doorType = 'Door';
          updatedItem.doorValue = value;
        }
        if (type === 'Case') {
          updatedItem.caseType = 'Case';
          updatedItem.caseValue = value;
        }

        return [...prev, updatedItem];
      }
    });
  };

  useEffect(() => {
    const initialData = [];

    projectsWithInvalidMaterials.forEach((p) => {
      const projectName = p?.updatedName;

      const existingProject = initialData.find(
        (item) => item.projectName === projectName
      );

      if (!existingProject) {
        const newProject = { projectName };

        if (!p.door) {
          newProject.doorType = 'Door';
          newProject.doorValue = defaultMaterial;
        }

        if (!p.case) {
          newProject.caseType = 'Case';
          newProject.caseValue = defaultMaterial;
        }

        initialData.push(newProject);
      } else {
        if (!p.door && !existingProject.doorType) {
          existingProject.doorType = 'Door';
          existingProject.doorValue = defaultMaterial;
        }

        if (!p.case && !existingProject.caseType) {
          existingProject.caseType = 'Case';
          existingProject.caseValue = defaultMaterial;
        }
      }
    });

    setFormData(initialData);
  }, [projectsWithInvalidMaterials]);

  return (
    <>
      <Grid container>
        <Grid item xs={12} lg={10} xl={9} px={{ sm: 0 }}>
          <Box display={'flex'} px={2} justifyContent={'space-between'}>
            <Typography
              flexWrap={'wrap'}
              sx={{
                fontSize: pxToRem(30),
                fontWeight: 700,
                maxWidth: '85%',
                wordBreak: 'break-word',
                mt: { xs: 2, sm: 0 },
              }}
            >
              My Projects
            </Typography>
          </Box>
          <Box px={2}>
            <Tabs
              value={path}
              onChange={handleChange}
              indicatorColor="white"
              textColor="inherit"
              variant="fullWidth"
              aria-label="full width tabs example"
              sx={{
                backgroundColor: '#E5E5E5',
                mt: 2,
              }}
            >
              {tabOptions?.map((tab) => {
                return (
                  <Tab
                    sx={{
                      '&.Mui-selected': {
                        fontWeight: 'bold',
                        backgroundColor: 'lightgrey',
                      },
                    }}
                    key={tab.value}
                    value={tab.value}
                    label={tab.label}
                  />
                );
              })}
            </Tabs>
          </Box>
          <Box sx={{ paddingTop: 2 }}>
            <Box sx={{ height: '100%', mx: 2 }}>
              <Paper elevation={0} sx={{ mb: 8 }}>
                <DataGrid
                  onRowSelectionModelChange={(selectedIds) => {
                    setSelectionModel(selectedIds);
                    const selectedRowData = fetchMyProjects.filter((row) =>
                      selectedIds.includes(row.id)
                    );
                    setSelectedRowIds(selectedIds);
                    setSelectedRows(selectedRowData);

                    checkForInvalidMat(selectedRowData);
                  }}
                  rowSelectionModel={selectionModel}
                  checkboxSelection
                  disableRowSelectionOnClick
                  onSortModelChange={(model) => {
                    setSortModel(model);
                  }}
                  sortingMode="server"
                  sortingOrder={['desc', 'asc']}
                  disableColumnMenu
                  sx={datagridSx}
                  rowCount={Number(fetchMyProjects?.[0]?.total_count) || 0}
                  pageSizeOptions={[10, 20, 30, 40, 50, 60, 70]}
                  pagination
                  paginationMode="server"
                  paginationModel={{
                    page: filter.page - 1,
                    pageSize: filter.limit,
                  }}
                  onPaginationModelChange={(e) => {
                    const { page, pageSize } = e;
                    setFilter({ ...filter, page: page + 1, limit: pageSize });
                  }}
                  rows={fetchMyProjects || []}
                  columns={columns || []}
                  loading={isLoading || isFetching}
                  slots={{
                    noRowsOverlay: () => (
                      <Stack
                        height="100%"
                        alignItems="center"
                        justifyContent="center"
                      >
                        {path === 'drafts'
                          ? 'No projects found'
                          : 'No orders found'}
                      </Stack>
                    ),
                  }}
                />
              </Paper>
              {openDialog && (
                <OrderItemDialog
                  open={openDialog}
                  handleClose={() => setOpenDialog(false)}
                  selectedItem={selectedItem}
                  projectType={path}
                />
              )}
            </Box>
          </Box>
        </Grid>
      </Grid>

      {selectedRows?.length !== 0 && (
        <StickyFooter>
          <Stack
            sx={{
              flexDirection: { xs: 'row' },
              justifyContent: { xs: 'center', md: 'end' },
            }}
            gap={2}
            width="100%"
          >
            <Stack
              sx={{ flexDirection: { xs: 'column', sm: 'row' } }}
              gap={'15px'}
              alignItems={'center'}
              justifyContent={'center'}
            >
              {path === 'drafts' && (
                <ButtonSx
                  sx={{
                    textTransform: 'uppercase',
                  }}
                  buttonText={`Delete Project${
                    selectedRows?.length > 1 ? 's' : ''
                  }`}
                  onButtonPress={() => {
                    const selectedRowData = selectedRows.map((item) => {
                      let name = item?.name || item?.projectName;
                      return {
                        ...item,
                        updatedName: `${name}-copy-${mergedObject[name]}`,
                      };
                    });
                    setSelectedRows(selectedRowData);
                    setIsAlertOpen(true);
                  }}
                />
              )}
              <ButtonSx
                sx={{
                  textTransform: 'uppercase',
                }}
                buttonText={`Duplicate Project${
                  selectedRows?.length > 1 ? 's' : ''
                }`}
                onButtonPress={() => {
                  const selectedRowData = selectedRows.map((item) => {
                    let name = item?.name || item?.projectName;
                    return {
                      ...item,
                      updatedName: `${name}-copy-${mergedObject[name]}`,
                    };
                  });
                  setProjectsWithInvalidMaterials((prev) =>
                    prev.map((item) => ({
                      ...item,
                      updatedName: `${item?.projectName}-copy-${
                        mergedObject[item?.projectName]
                      }`,
                    }))
                  );
                  setSelectedRows(selectedRowData);
                  setProjectPopupOpen(true);
                }}
              />
            </Stack>
          </Stack>
        </StickyFooter>
      )}
      {isProjectPopupOpen && (
        <ProjectPopup
          desc={
            matSelectionPage.page === 1
              ? 'Please choose materials for the projects listed below'
              : 'Note: Make sure to verify the name of the duplicated project'
          }
          isOpen={isProjectPopupOpen}
          onClose={handleClose}
          title={'Duplicate projects'}
          cancelButtonText={!matSelectionPage.page ? 'Cancel' : 'Back'}
          submitButtonText={
            projectsWithInvalidMaterials?.length && matSelectionPage.page === 0
              ? 'Next'
              : 'Duplicate'
          }
          isConfirmLoading={isDuplicatePLoading}
          paperStyles={{ minWidth: '600px' }}
          isConfirmDisabled={!!Object.keys(errors).length}
          childComponent={
            <>
              <Divider sx={{ mb: 1 }} />
              {matSelectionPage.page === 1 ? (
                <form>
                  <Stack
                    sx={{
                      my: 2,
                      px: 1,
                      maxHeight: '300px',
                      overflowY: 'auto',
                    }}
                  >
                    {projectsWithInvalidMaterials?.map((p) => {
                      const selectedMatData =
                        formData?.find(
                          (item) => item.projectName === p?.updatedName
                        ) || {};
                      return (
                        <Stack key={p?.projectId}>
                          <Typography variant="h6">{p?.updatedName}</Typography>
                          <Stack
                            direction={'row'}
                            gap={3}
                            sx={{
                              flexDirection: { xs: 'column', lg: 'row' },
                              my: 3,
                            }}
                          >
                            {!p?.case && (
                              <TextField
                                select
                                label={'Case Material'}
                                fullWidth
                                value={
                                  selectedMatData?.caseValue || defaultMaterial
                                }
                                onChange={(e) =>
                                  handleProjectDataChange(
                                    'Case',
                                    e.target.value,
                                    p?.updatedName
                                  )
                                }
                              >
                                {caseMaterials?.map((item) => (
                                  <MenuItem value={item.name} key={item.id}>
                                    {item.name}
                                  </MenuItem>
                                ))}
                              </TextField>
                            )}

                            {!p?.door && (
                              <TextField
                                select
                                label={'Door Material'}
                                fullWidth
                                value={
                                  selectedMatData?.doorValue || defaultMaterial
                                }
                                onChange={(e) =>
                                  handleProjectDataChange(
                                    'Door',
                                    e.target.value,
                                    p?.updatedName
                                  )
                                }
                              >
                                {doorMaterials?.map((item) => (
                                  <MenuItem value={item.name} key={item.id}>
                                    {item.name}
                                  </MenuItem>
                                ))}
                              </TextField>
                            )}
                          </Stack>
                        </Stack>
                      );
                    })}
                  </Stack>
                </form>
              ) : (
                <form>
                  <Stack
                    gap={2}
                    sx={{
                      p: 1,
                      overflowY: 'auto',
                      height: 'auto',
                      maxHeight: '300px',
                    }}
                  >
                    {selectedRows?.map((project, index) => {
                      const hasMissingCab = project.cabinetCodes.filter(
                        (code) => !code.exists
                      );
                      const hasInvalidMat = projectsWithInvalidMaterials
                        ?.map((item) => item?.projectId)
                        ?.includes(project?.projectId);
                      return (
                        <Stack
                          key={project?.id}
                          direction={'row'}
                          justifyContent={'space-between'}
                          alignItems={'center'}
                          gap={2}
                        >
                          <Controller
                            key={project?.id}
                            control={control}
                            name={`projects.${index}.name`}
                            defaultValue={project?.updatedName}
                            render={({ field }) => {
                              const { onChange } = field;
                              return (
                                <>
                                  <TextField
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment position="end">
                                          {!!hasMissingCab?.length && (
                                            <Tooltip
                                              title={`Cabinet(s) ${hasMissingCab
                                                ?.map((item) => item.name)
                                                .join(
                                                  ','
                                                )} no longer exists. Make sure to review the duplicated projects before ordering.`}
                                              arrow
                                            >
                                              <InfoOutlinedIcon
                                                sx={{
                                                  color: 'blue',
                                                }}
                                              />
                                            </Tooltip>
                                          )}
                                        </InputAdornment>
                                      ),
                                    }}
                                    {...field}
                                    onChange={(e) => {
                                      const newValue = e.target.value;

                                      setProjectsWithInvalidMaterials((prev) =>
                                        prev.map((item) =>
                                          item?.projectId === project?.projectId
                                            ? { ...item, updatedName: newValue }
                                            : item
                                        )
                                      );

                                      setSelectedRows((prev) =>
                                        prev.map((item) =>
                                          item?.id === project?.id
                                            ? { ...item, updatedName: newValue }
                                            : item
                                        )
                                      );
                                      onChange(e);
                                    }}
                                    key={project?.id}
                                    sx={{ width: '100%' }}
                                    error={
                                      !!errors.projects?.[index]?.name ||
                                      hasInvalidMat
                                    }
                                    helperText={
                                      hasInvalidMat
                                        ? 'Project with Invalid material'
                                        : errors.projects?.[index]?.name
                                            ?.message
                                    }
                                    inputProps={{ maxLength: 70 }}
                                  />
                                </>
                              );
                            }}
                          />
                        </Stack>
                      );
                    })}
                  </Stack>
                </form>
              )}

              <Typography sx={{ p: 1 }}>
                {projectsWithInvalidMaterials?.length !== 0 &&
                  matSelectionPage.page !== 1 &&
                  'Warning: The door/case material of the selected project(s) no longer exists. Please click Next to select a different material.'}
              </Typography>
            </>
          }
          onConfirm={() => {
            if (
              matSelectionPage.page === 0 &&
              projectsWithInvalidMaterials?.length
            ) {
              setMatSelectionPage({ page: 1 });
            } else {
              let body;
              body = {
                data: selectedRows.map((item) => {
                  const materialData =
                    formData.find(
                      (fd) => fd.projectName === item.updatedName
                    ) || {};

                  return {
                    duplicatedFrom:
                      path === 'invoiced' ? item?.projectId : item?.id,
                    name: item.updatedName,
                    orderInfoId: item.orderInfoId,
                    orderProjectsId: item?.id,
                    validMatData: materialData,
                  };
                }),
              };
              createDuplicateProjects(body);
              resetState();
            }
          }}
        />
      )}

      {isAlertOpen && (
        <AlertPopup
          title={'Delete Project?'}
          description={
            'Are you sure you want to delete this Project? All the cabinet information added will be lost. This action cannot be undone.'
          }
          submitButtonText="Confirm"
          isOpen={isAlertOpen}
          handleClose={() => {
            setSelectedRows([]);
            setSelectionModel([]);
            setConfirmValue('');
            setIsAlertOpen(false);
          }}
          isConfirmLoading={isProjectDeleting}
          isConfirmDisabled={confirmValue.toLowerCase() !== 'delete'}
          onConfirm={handleItemDelete}
        >
          <Box px={3}>
            <Typography
              fontWeight={'bold'}
              sx={{ color: (theme) => theme.palette.text.secondary }}
            >
              Please type in DELETE to confirm
            </Typography>
            <TextField
              fullWidth
              value={confirmValue}
              onChange={(e) => {
                setConfirmValue(e.target.value);
              }}
              placeholder="Type to confirm"
              id="outlined-basic"
              variant="outlined"
              sx={{
                'mt': 1,
                '& .MuiInputBase-root': {
                  height: 50,
                  backgroundColor: 'white',
                },
              }}
            />
          </Box>
        </AlertPopup>
      )}
    </>
  );
};

export default MyProjects;
