import React, { useEffect, useState } from 'react';

import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteIcon from '@mui/icons-material/Delete';
import { Alert, Button, Grid, IconButton, Stack, Tooltip } from '@mui/material';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import AccordionCustom from 'components/accordian/AccordionCustom';
import AlertPopup from 'components/alert-popup/AlertPopup';
import ButtonSx from 'components/button/ButtonSx';
import CartItem, { MaterialItem } from 'components/cart-item/CartItem';
import StickyFooter from 'components/sticky-footer/StickyFooter';
import useAuth from 'context/AuthContext';
import {
  useAcknowledgeInvalidMatMutation,
  useAddProjectToCartMutation,
  useGetCartProjectsQuery,
  useGetUserCartByProjectQuery,
  useRemoveCartItemMutation,
  useRemoveProjectFromSalesOrderMutation,
  useUpdateCabinetOrderMutation,
} from 'store/apis/order';
import {
  setSelectedProject,
  updateSelectedProject,
} from 'store/slices/projectSlice';
import { primaryColor } from 'theme-loader/theme/colors';
import { pxToRem } from 'theme-loader/theme/typography';
import { useNotifyToast } from 'utils/useNotifyToast';

export const headerSx = {
  fontSize: pxToRem(15),
  fontWeight: 600,
};

const YourOrder = ({
  showRemoveBtn = true,
  parentData = null,
  isOrderDetails = false,
}) => {
  const navigate = useNavigate();

  const projectData = useSelector((state) => state.project.selectedProject);
  const [removeCartItem, { isLoading, error }] = useRemoveCartItemMutation();
  const [isDeleteAlertOpen, setIsDeleteAlertOpen] = useState(null);
  const [isRemoveProjectAlertOpen, setIsRemoveProjectAlertOpen] =
    useState(null);
  const auth = useAuth();
  const [accordion, setAccordion] = useState({});
  const notifyToast = useNotifyToast();
  const dispatch = useDispatch();
  const [
    addProjectToCart,
    { data, isLoading: isAddLoading, isSuccess, isError, error: addError },
  ] = useAddProjectToCartMutation();
  const { data: cartItemsByProject, isFetching: cartDataByProjectIsFetching } =
    useGetUserCartByProjectQuery(
      { id: parentData?.id ?? projectData?.id },
      {
        refetchOnMountOrArgChange: true,
        skip: !auth.authenticated || !projectData?.id,
      }
    );

  const handleItemDelete = async () => {
    const response = await removeCartItem(isDeleteAlertOpen.id);

    if (response?.data) {
      if (cartItemsByProject?.length === 1) {
        await removeProjectFromSalesOrder(parentData?.id ?? projectData?.id);
      }
      notifyToast(response?.data?.message, 'removeCartItemSuccess', 'success');
    } else {
      notifyToast(error?.data?.errorMessage, 'removeCartItemError', 'error');
    }
    setIsDeleteAlertOpen(null);

    if (
      parentData?.id === projectData?.id ||
      projectData?.cabinets.length === 0
    ) {
      dispatch(updateSelectedProject({ addedToCart: false }));
    }
  };

  useEffect(() => {
    if (!isAddLoading) {
      if (isSuccess) {
        dispatch(updateSelectedProject({ addedToCart: true }));
        notifyToast(data?.message, 'projectAddedToCart', 'success');
      } else if (isError && addError) {
        notifyToast(
          error?.data?.errorMessage,
          'projectAddToCartFailed',
          'error'
        );
      }
    }
  }, [
    addError,
    data?.message,
    dispatch,
    error?.data?.errorMessage,
    isAddLoading,
    isError,
    isSuccess,
    notifyToast,
  ]);

  const { data: cartData } = useGetCartProjectsQuery(
    {},
    {
      refetchOnMountOrArgChange: true,
      skip: !auth.authenticated || parentData,
    }
  );

  const cartTotal = Number(
    cartItemsByProject
      ?.reduce((partialSum, a) => Number(partialSum) + Number(a.itemTotal), 0)
      .toPrecision(10)
  ).toFixed(2);

  const [removeProjectFromSalesOrder, { isLoading: removeProjectLoading }] =
    useRemoveProjectFromSalesOrderMutation();

  const handleRemoveProject = async () => {
    const response = await removeProjectFromSalesOrder(projectData?.id);
    if (response?.data) {
      setIsRemoveProjectAlertOpen(false);
      dispatch(updateSelectedProject({ addedToCart: false }));
      notifyToast(response?.data?.message, 'removeProjectSuccess', 'success');
    } else {
      notifyToast(error?.data?.errorMessage, 'removeProjectError', 'error');
    }
    setIsDeleteAlertOpen(null);
  };

  const handleChange = (id) => {
    setAccordion((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  useEffect(() => {
    const itemIds = {};
    cartItemsByProject?.forEach((item) => (itemIds[item.id] = false));
    setAccordion(itemIds);
  }, [cartItemsByProject]);
  const materialItems = [
    {
      title: 'CASE MATERIAL',
      value: cartItemsByProject?.[0]?.caseMaterialName,
      type: 'settings',
    },
    {
      title: 'DOOR MATERIAL',
      value: cartItemsByProject?.[0]?.doorMaterialName,
      type: 'settings',
    },
    {
      title: 'DRAWER MATERIAL',
      value: cartItemsByProject?.[0]?.drawerBoxName,
      type: 'settings',
    },
    {
      title: 'ASSEMBLY',
      value: cartItemsByProject?.[0]?.assemblyName,
      type: 'settings',
    },
    {
      title: 'CASE EDGEBANDING',
      value:
        cartItemsByProject?.[0]?.caseBandLF === 'matchCase'
          ? 'Match Case Material'
          : 'Match Door Material',
      type: 'settings',
    },
  ];

  const [acknowledgeInvalidMat] = useAcknowledgeInvalidMatMutation();

  const handleAlertClose = async () => {
    setIsInfoAlertOpen(false);
    acknowledgeInvalidMat(parentData?.id ?? projectData?.id);
  };

  const [isInfoAlertOpen, setIsInfoAlertOpen] = useState(false);

  useEffect(() => {
    setIsInfoAlertOpen(
      !!Object.keys(cartItemsByProject?.[0]?.deletedMaterialInfo || {})?.length
    );
  }, [cartItemsByProject]);

  // code to handle the dnd of cabinets

  const [cabinetsData, setCabinetsData] = useState(
    cartItemsByProject ? cartItemsByProject : []
  );

  const [updateCabinetOrder] = useUpdateCabinetOrderMutation();

  const handleOnDragEnd = async (result) => {
    if (!result.destination) return; // Ignore if dropped outside

    let listItems = [...cabinetsData];

    // Extract dragged item
    const draggedItem = listItems[result.source.index];

    // Remove item from the old position
    listItems.splice(result.source.index, 1);

    // Insert item at new position
    listItems.splice(result.destination.index, 0, draggedItem);

    // Get the previous and next item's ranks
    const prevItem = listItems[result.destination.index - 1] || null;
    const nextItem = listItems[result.destination.index + 1] || null;

    let newRank, nextItemRank, prevItemRank;
    if (nextItem) {
      nextItemRank = Number(
        nextItem.rank || new Date(nextItem.createdAt).valueOf()
      );
    }
    if (prevItem) {
      prevItemRank = Number(
        prevItem.rank || new Date(prevItem.createdAt).valueOf()
      );
    }

    if (!prevItem && nextItem) {
      newRank = nextItemRank / 2;
    } else if (prevItem && !nextItem) {
      newRank = prevItemRank + 1;
    } else if (prevItem && nextItem) {
      newRank = (prevItemRank + nextItemRank) / 2;
    } else {
      newRank = Date.now();
    }

    const payload = {
      projectId: parentData?.id ?? projectData?.id,
      code: draggedItem?.code,
      rank: newRank,
    };

    setCabinetsData([...listItems]);
    await updateCabinetOrder(payload);
  };

  useEffect(() => {
    if (!cartDataByProjectIsFetching && cartItemsByProject) {
      setCabinetsData(cartItemsByProject);
    }
  }, [cartDataByProjectIsFetching, cartItemsByProject]);

  return cartDataByProjectIsFetching ? (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
      }}
    >
      <CircularProgress />
    </Box>
  ) : (
    <Box>
      <Box px={2}>
        {cabinetsData?.length ? (
          <>
            {isInfoAlertOpen && (
              <Alert severity="info" onClose={handleAlertClose}>
                {`${Object.entries(cabinetsData?.[0]?.deletedMaterialInfo || {})
                  .map(
                    ([key, value]) =>
                      `${key.charAt(0).toUpperCase() + key.slice(1)} (${value})`
                  )
                  .join(
                    ', '
                  )} material(s) are unavailable and have been set to default options. Please review your selections.`}
              </Alert>
            )}

            <Box mb={2}>
              <Typography sx={{ ...headerSx, mt: 3 }}>MATERIALS</Typography>
              <Grid
                container
                columnSpacing={4}
                rowSpacing={1}
                alignItems={'flex-start'}
              >
                {materialItems?.map((mat) => (
                  <MaterialItem
                    key={mat?.title}
                    title={mat?.title}
                    value={mat?.value}
                    type={mat?.type}
                  />
                ))}
              </Grid>
            </Box>
            <Box
              display={'flex'}
              flexDirection={'row'}
              alignItems={'center'}
              justifyContent={'flex-end'}
            >
              <Button
                variant="contained"
                size="small"
                sx={{
                  'fontSize': pxToRem(12),
                  'fontWeight': 700,
                  'backgroundColor': '#f0f0f0',
                  'color': '#DB401A',
                  'py': 1,
                  'width': 100,
                  'borderRadius': 2,
                  '&:hover': {
                    backgroundColor: '#E5E5E5',
                    color: '#DB401A',
                  },
                }}
                onClick={() => {
                  if (Object.values(accordion).some((item) => !!item)) {
                    setAccordion({});
                  } else {
                    const final = {};
                    cabinetsData.forEach((item) => {
                      final[item.id] = true;
                    });
                    setAccordion(final);
                  }
                }}
              >
                {cabinetsData?.length &&
                Object.values(accordion).some((item) => !!item)
                  ? 'Collapse All'
                  : 'Expand All'}
              </Button>
            </Box>
          </>
        ) : null}
      </Box>
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <Droppable droppableId="cabinets">
          {(provided) => (
            <Box
              {...provided.droppableProps}
              ref={provided.innerRef}
              mb={parentData?.id ? 0 : 25}
              pt={2.5}
            >
              {cabinetsData?.length ? (
                cabinetsData?.map((item, index) => {
                  return (
                    <Draggable
                      key={item?.id}
                      draggableId={item?.id}
                      index={index}
                    >
                      {(provided) => (
                        <Box
                          key={item?.id}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          ref={provided.innerRef}
                        >
                          <AccordionCustom
                            title={
                              <Stack>
                                <Grid container>
                                  <Grid
                                    item
                                    xs={7.5}
                                    sm={8.5}
                                    md={10}
                                    sx={{
                                      display: 'flex',
                                      alignItems: 'center',
                                    }}
                                  >
                                    <Grid container spacing={'10px'}>
                                      <Grid
                                        item
                                        xs={12}
                                        md={2}
                                        sx={{
                                          display: 'flex',
                                          alignItems: 'center',
                                          gap: '15px',
                                        }}
                                      >
                                        <Typography
                                          variant="h4"
                                          sx={{
                                            fontSize: pxToRem(16),
                                            // mr: 2,
                                          }}
                                        >
                                          {index + 1}.
                                        </Typography>
                                        <Typography
                                          variant="h4"
                                          sx={{
                                            fontSize: pxToRem(16),
                                            fontWeight: 600,
                                          }}
                                        >
                                          {item?.code}
                                        </Typography>
                                      </Grid>
                                      <Grid item xs={12} sm={12} md={6} lg={3}>
                                        <Typography
                                          sx={{
                                            fontSize: pxToRem(16),
                                            // ml: { xs: 0, sm: 1 },
                                            // mt: { xs: 1, sm: 0 },
                                          }}
                                        >
                                          {item?.shortName}
                                        </Typography>
                                      </Grid>
                                      <Grid item xs={12} sm={12} md={4} lg={4}>
                                        <Box
                                          sx={{
                                            display: 'flex',
                                            justifyContent: { md: 'center' },
                                            alignItems: 'center',
                                          }}
                                        >
                                          <Typography
                                            sx={{
                                              fontSize: pxToRem(16),
                                              mr: { xs: 1, sm: 2, lg: 5 },
                                              mb: { xs: 1, sm: 0 },
                                            }}
                                          >
                                            {item.width && `W: ${item.width}"`}{' '}
                                            {item.height &&
                                              `${item.width ? 'X' : ''} ${
                                                item.isLengthType ? 'L' : 'H'
                                              }: ${item.height}"`}{' '}
                                            {item.depth &&
                                              `${
                                                item.width || item?.height
                                                  ? 'X'
                                                  : ''
                                              } D: ${item.depth}"`}
                                          </Typography>
                                        </Box>
                                      </Grid>
                                      <Grid item lg={3}>
                                        <Box
                                          sx={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                          }}
                                        >
                                          <Typography
                                            sx={{
                                              fontSize: pxToRem(16),
                                              mr: 1,
                                            }}
                                          >
                                            {item.quantity}
                                          </Typography>
                                          <Typography sx={{ mr: 1 }}>
                                            X
                                          </Typography>
                                          <Typography
                                            sx={{
                                              fontSize: pxToRem(16),
                                              textAlign: 'center',
                                            }}
                                          >
                                            $
                                            {Number(
                                              item.itemTotal / item.quantity
                                            )?.toFixed(2)}
                                          </Typography>
                                          <Typography
                                            sx={{
                                              fontSize: pxToRem(16),
                                              textAlign: 'center',
                                              mx: 1,
                                            }}
                                          >
                                            =
                                          </Typography>
                                          <Typography
                                            sx={{
                                              fontSize: pxToRem(16),
                                              fontWeight: 600,
                                              textAlign: 'right',
                                            }}
                                          >
                                            $
                                            {Number(item.itemTotal)?.toFixed(2)}
                                          </Typography>
                                        </Box>
                                      </Grid>
                                    </Grid>
                                  </Grid>

                                  <Grid xs={3.5} md={2} item>
                                    <Box
                                      sx={{
                                        mr: { xs: '-20px', sm: '10px' },
                                      }}
                                    >
                                      {!isOrderDetails && (
                                        <>
                                          <IconButton
                                            sx={{ p: 1, float: 'right' }}
                                            onClick={(e) => {
                                              e.stopPropagation();
                                              setIsDeleteAlertOpen(item);
                                            }}
                                            color="inherit"
                                          >
                                            <DeleteIcon
                                              sx={{
                                                color: (theme) =>
                                                  theme.palette.error.dark,
                                              }}
                                            />
                                          </IconButton>
                                          <IconButton
                                            size="large"
                                            sx={{ p: 1, float: 'right' }}
                                            onClick={() => {
                                              if (parentData) {
                                                dispatch(
                                                  setSelectedProject(parentData)
                                                );
                                              }
                                              navigate('/customize', {
                                                state: {
                                                  selectedCabinet: item,
                                                  isEdit: true,
                                                },
                                              });
                                            }}
                                            color="inherit"
                                          >
                                            <BorderColorIcon
                                              sx={{
                                                color: (theme) =>
                                                  theme.palette.success.light,
                                              }}
                                            />
                                          </IconButton>
                                          <IconButton
                                            size="large"
                                            sx={{ p: 1, float: 'right' }}
                                            onClick={() => {
                                              if (parentData) {
                                                dispatch(
                                                  setSelectedProject(parentData)
                                                );
                                              }
                                              navigate('/customize', {
                                                state: {
                                                  selectedCabinet: item,
                                                  isDuplicate: true,
                                                },
                                              });
                                            }}
                                            color="inherit"
                                          >
                                            <Tooltip
                                              title={`Duplicate ${item?.code} cabinet`}
                                            >
                                              <ContentCopyIcon />
                                            </Tooltip>
                                          </IconButton>
                                        </>
                                      )}
                                    </Box>
                                  </Grid>
                                </Grid>
                                <Box
                                  sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                  }}
                                >
                                  <Stack>
                                    {item.note?.trim()?.length ? (
                                      <Typography sx={{ fontWeight: 600 }}>
                                        Notes:{' '}
                                        <span style={{ fontWeight: 400 }}>
                                          {item.note?.trim()}
                                        </span>
                                      </Typography>
                                    ) : null}
                                    {item.specialInstructions?.trim()
                                      ?.length ? (
                                      <Typography sx={{ fontWeight: 600 }}>
                                        Special Instructions:{' '}
                                        <span style={{ fontWeight: 400 }}>
                                          {item.specialInstructions?.trim()}
                                        </span>
                                      </Typography>
                                    ) : null}
                                  </Stack>
                                </Box>
                              </Stack>
                            }
                            expanded={!!accordion?.[item?.id]}
                            handleChange={() => handleChange(item?.id)}
                            sx={{
                              '& .MuiAccordionSummary-root': {
                                background: '#f0f0f0',
                              },
                              '&::before': {
                                backgroundColor: 'transparent',
                              },
                            }}
                          >
                            <CartItem cartItem={item} key={item.id} />
                          </AccordionCustom>
                          <Divider
                            variant="fullWidth"
                            sx={{
                              border: (theme) =>
                                `1px solid ${theme.palette.grey[400]}`,
                            }}
                          />
                        </Box>
                      )}
                    </Draggable>
                  );
                })
              ) : (
                <Box
                  sx={{
                    height: '20vh',
                    py: 10,
                  }}
                >
                  <Typography textAlign={'center'}>
                    No cabinets added to this Project!
                  </Typography>
                  <Typography
                    onClick={() => {
                      navigate('/');
                    }}
                    value={'Add a New Project'}
                    key={'add'}
                    sx={{
                      color: primaryColor[900],
                      textDecorationLine: 'underline',
                      textAlign: 'center',
                      cursor: 'pointer',
                    }}
                  >
                    Browse cabinets
                  </Typography>
                </Box>
              )}
            </Box>
          )}
        </Droppable>
      </DragDropContext>

      {showRemoveBtn && cabinetsData?.length ? (
        <StickyFooter>
          <Box
            display={'flex'}
            justifyContent={'flex-end'}
            alignItems={'center'}
            sx={{ mb: { xs: 2, sm: 0 } }}
          >
            <Typography
              variant="h4"
              fontWeight={300}
              sx={{
                ml: { xs: 2, sm: 3, md: 6 },
              }}
            >
              Total
            </Typography>
            <Typography
              variant="h4"
              fontWeight={'fontWeightBold'}
              sx={{
                mx: { xs: 2, sm: 3, md: 6 },
              }}
            >
              {/* ${Number(cartTotal) + Number(perOrderCost * cartItems?.length)} */}
              ${cartTotal}
            </Typography>
          </Box>
          <ButtonSx
            sx={{ width: { xs: '100%', sm: 'auto' } }}
            buttonText={
              projectData?.addedToCart
                ? 'REMOVE FROM SHOPPING CART'
                : 'ADD TO SHOPPING CART'
            }
            onButtonPress={() => {
              if (projectData?.addedToCart) {
                setIsRemoveProjectAlertOpen(true);
              } else {
                addProjectToCart({ projectId: projectData?.id });
              }
            }}
          />
        </StickyFooter>
      ) : null}

      <AlertPopup
        title={'Remove cabinet from Project?'}
        description={
          cabinetsData?.length === 1 &&
          cartData?.projectData?.some((item) => item.id === projectData?.id)
            ? 'Are you sure you want to remove this item from the Project? This will also remove the Project from Shopping Cart as no cabinets will be left in the Project for ordering. This action cannot be undone. '
            : 'Are you sure you want to remove this item from the Project? This action cannot be undone.'
        }
        submitButtonText="Confirm"
        isOpen={!!isDeleteAlertOpen?.id}
        handleClose={() => setIsDeleteAlertOpen(null)}
        isConfirmLoading={isLoading}
        onConfirm={() => handleItemDelete()}
      />
      <AlertPopup
        title={'Remove Project from Shopping Cart?'}
        description={
          'Are you sure you want to remove this Project from Shopping Cart?'
        }
        submitButtonText="Confirm"
        isOpen={isRemoveProjectAlertOpen}
        handleClose={() => setIsRemoveProjectAlertOpen(null)}
        isConfirmLoading={removeProjectLoading}
        onConfirm={() => handleRemoveProject()}
      />
    </Box>
  );
};

YourOrder.propTypes = {
  isOrderDetails: PropTypes.bool,
  parentData: PropTypes.shape({
    id: PropTypes.any,
    projectData: PropTypes.shape({
      length: PropTypes.any,
    }),
  }),
  projectId: PropTypes.any,
  projectNumber: PropTypes.any,
  showRemoveBtn: PropTypes.bool,
};

export default YourOrder;
