import React, { useEffect, useRef } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import './OrderIemDialog.css';

// import useAuth from 'context/AuthContext';
// import { useGetCustomerQuery } from 'store/apis/customer';
import {
  // useGetAllMaterialsQuery,
  useGetOrderItemsQuery,
} from 'store/apis/order';

import OrderedProjects from './OrderedProjects';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} {...other}>
      {value === index ? (
        <Box sx={{ py: 1 }}>
          <Typography component={'span'}>{children}</Typography>
        </Box>
      ) : null}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes?.node,
  index: PropTypes?.number?.isRequired,
  value: PropTypes?.number?.isRequired,
};

const OrderItemDialog = (props) => {
  const { open, selectedItem, handleClose, projectType } = props;
  const [value, setValue] = React.useState(0);
  const { row, id } = selectedItem;

  const ref = useRef();

  const { data, isLoading } = useGetOrderItemsQuery(id);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    ref?.current?.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const customerInfo = [
    {
      title: 'Name',
      value: row?.firstName + ' ' + row?.lastName,
    },
    {
      title: 'Email',
      value: row?.email,
    },
    {
      title: 'Phone',
      value: row?.phone,
    },
  ];

  const deliveryInfo = [
    {
      title: 'Contact Name',
      value: row?.contactName,
    },
    {
      title: 'Address',
      value: row?.address,
    },
    {
      title: 'Address2',
      value: row?.address2,
    },
    {
      title: 'Email',
      value: row?.deliveryEmail,
    },
    {
      title: 'Phone',
      value: row?.deliveryPhone,
    },
    {
      title: 'City',
      value: row?.city,
    },
    {
      title: 'State',
      value: row?.state,
    },
    {
      title: 'Zip',
      value: row?.zipCode,
    },
    {
      title: 'Shipping Method',
      value: row?.shippingMethod,
    },
    {
      title: 'Shipping Note',
      value: row?.shippingNote,
    },
  ];

  const billingInfo = [
    {
      title: 'Name',
      value: row?.billingFirstName + '  ' + row?.billingLastName,
    },
    {
      title: 'Address',
      value: row?.billingAddress,
    },
    {
      title: 'Address2',
      value: row?.billingAddress2,
    },
    {
      title: 'Phone',
      value: row?.billingPhone,
    },
    {
      title: 'Email',
      value: row?.billingEmail,
    },
    {
      title: 'City',
      value: row?.billingCity,
    },
    {
      title: 'State',
      value: row?.billingState,
    },
    {
      title: 'Zip',
      value: row?.billingZip,
    },
  ];

  const orderInfo = [
    {
      title: 'Total Item',
      value: row?.totalItem,
    },
    {
      title: 'Promo Code',
      value: row?.appliedPromoCode,
    },
    {
      title: 'Discount Amount',
      value: row?.discountAmount,
    },
    {
      title: 'Tax',
      value: row?.tax,
    },
    {
      title: 'Order Status',
      value: row?.orderStatus,
    },
    {
      title: 'Note',
      value: row?.note,
    },
    {
      title: 'Total Cost',
      value: row?.totalCost,
    },
    {
      title: projectType ? 'Cabinets' : 'Projects',
      value: projectType
        ? data?.orderItems?.map((item) => item?.code).join(', ') || ''
        : row?.projects?.map((item) => item.projectName).join(', ') || '-',
    },
  ];

  useEffect(() => {
    window?.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  const sectionsData = [
    { title: 'Customer Info', data: customerInfo },
    { title: 'Order Info', data: orderInfo },
    { title: 'Delivery Info', data: deliveryInfo },
    { title: 'Billing Info', data: billingInfo },
  ];

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        sx: {
          width: '100%',
          maxWidth: '1200px !important',
          overflowY: 'unset',
          maxHeight: '700px',
          height: '100%',
        },
      }}
      disableScrollLock
    >
      <DialogTitle>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography>
            <b>Order Items</b>
          </Typography>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>

        <Tabs value={value} onChange={handleChange} variant="fullWidth">
          {['info', 'Projects']?.map((item) => (
            <Tab label={item} key={item} />
          ))}
        </Tabs>
      </DialogTitle>

      <DialogContent sx={{ height: '400px' }} ref={ref}>
        {isLoading ? (
          <Box
            width="100%"
            height="100%"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <CircularProgress />
          </Box>
        ) : null}
        {!isLoading ? (
          <>
            {[
              {
                child: (
                  <>
                    {sectionsData?.map((section, index) => {
                      return (
                        <Box key={index}>
                          <Typography key={index} variant="display6" paragraph>
                            {section?.title} :
                          </Typography>

                          <Grid container spacing={2}>
                            {section?.data?.map((item, index) => (
                              <React.Fragment key={index}>
                                {item?.value ? (
                                  <Grid
                                    item
                                    xs={12}
                                    sm={6}
                                    md={4}
                                    className="ellipsis"
                                  >
                                    <Typography>{item?.title}</Typography>
                                    <Typography variant="subtitle">
                                      {item?.value}
                                    </Typography>
                                  </Grid>
                                ) : null}
                              </React.Fragment>
                            ))}
                          </Grid>
                          {index !== sectionsData?.length - 1 && (
                            <Divider sx={{ my: 2 }} />
                          )}
                        </Box>
                      );
                    })}
                  </>
                ),
              },
              {
                child: (
                  <OrderedProjects
                    data={data}
                    row={row}
                    projectType={projectType}
                  />
                ),
              },
            ]?.map((item, index) => (
              <TabPanel value={value} key={index} index={index}>
                {item?.child}
              </TabPanel>
            ))}
          </>
        ) : null}
      </DialogContent>
    </Dialog>
  );
};

OrderItemDialog.propTypes = {
  open: PropTypes?.bool,
  selectedItem: PropTypes?.object,
  handleClose: PropTypes?.func,
  projectType: PropTypes?.string,
};

export default OrderItemDialog;
