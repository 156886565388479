import React from 'react';

import { Box, Divider, Grid, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';

import AccordionCustom from 'components/accordian/AccordionCustom';
import YourOrder from 'pages/your-order/YourOrder';
import { pxToRem } from 'theme-loader/theme/typography';

const NestedAccordion = ({
  data,
  actionComponents = () => {},
  isOrderDetails = false,
  childComponent: ChildComponent = null,
}) => {
  return (
    <Box pt={2.5} px={{ xs: 2, sm: 0 }}>
      {data?.projectData?.length
        ? data?.projectData?.map((item, index) => {
            return (
              <Box key={item?.id} sx={{ mb: 1 }}>
                <AccordionCustom
                  title={
                    <Stack>
                      <Grid container sx={{ justifyContent: 'space-between' }}>
                        <Grid item>
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'row',
                              alignItems: 'center',
                              mt: '5px',
                            }}
                          >
                            <Typography
                              sx={{
                                fontSize: pxToRem(16),
                                fontWeight: 500,
                                mr: 2,
                              }}
                            >
                              {index + 1}.
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: pxToRem(16),
                              }}
                            >
                              {item?.name || item?.projectName ? (
                                <>
                                  {item?.name || item.projectName}{' '}
                                  {item.projectName && (
                                    <Typography
                                      component="span"
                                      sx={{
                                        ml: 2,
                                        fontSize: pxToRem(16),
                                      }}
                                    >
                                      ({item.projectNo})
                                    </Typography>
                                  )}
                                </>
                              ) : (
                                ''
                              )}
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item>
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'row',
                              alignItems: 'center',
                            }}
                          >
                            <Typography
                              sx={{
                                fontSize: pxToRem(16),
                                fontWeight: 600,
                                textAlign: 'right',
                                mr: 2,
                              }}
                            >
                              $ {Number(item.totalCost)?.toFixed(2)}
                            </Typography>
                            {actionComponents && actionComponents(item)}
                          </Box>
                        </Grid>
                      </Grid>
                      {item?.projectSpecificNotes && (
                        <Typography sx={{ fontWeight: 600 }}>
                          Notes:{' '}
                          <span style={{ fontWeight: 400 }}>
                            {item?.projectSpecificNotes}
                          </span>
                        </Typography>
                      )}
                      {item?.projectSpecialInstructions && (
                        <Typography sx={{ fontWeight: 600 }}>
                          Special Instructions:{' '}
                          <span style={{ fontWeight: 400 }}>
                            {item?.projectSpecialInstructions}
                          </span>
                        </Typography>
                      )}
                    </Stack>
                  }
                  sx={{
                    '& .MuiAccordionSummary-root': {
                      background: '#f0f0f0',
                    },
                    '&::before': {
                      backgroundColor: 'transparent',
                    },
                  }}
                >
                  {ChildComponent ? (
                    <ChildComponent item={item} />
                  ) : (
                    <YourOrder
                      showRemoveBtn={false}
                      parentData={item}
                      isOrderDetails={isOrderDetails}
                    />
                  )}
                </AccordionCustom>
                <Divider
                  variant="fullWidth"
                  sx={{
                    border: (theme) => `1px solid ${theme.palette.grey[400]}`,
                  }}
                />
              </Box>
            );
          })
        : null}
    </Box>
  );
};

NestedAccordion.propTypes = {
  actionComponents: PropTypes.func,
  childComponent: PropTypes.any,
  data: PropTypes.any,
  isOrderDetails: PropTypes.bool,
};

export default NestedAccordion;
